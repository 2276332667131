<template>
	<div id="login-acc" :style="'background: #000 url('+$system_.state.applic_bg+') no-repeat;'">
		<div class="login-div">
			<!-- 切换登录 -->
			<div class="login-box" v-if="ifAccount.num">
				<span class="box-text"  @click="switchLogin">{{whichLogin === 'account' ? '切换扫码登录' : '切换账户登录'}}</span>
				<el-image class="box-img" v-if="whichLogin === 'code'"  @click="switchLogin" style="width: 60px; height: 60px" :src="require('@/assets/img/account_login.png')" fit="contain" />
				<el-image class="box-img" v-else-if="whichLogin === 'account'"  @click="switchLogin" style="width: 60px; height: 60px" :src="require('@/assets/img/code_login.png')" fit="contain" />
			</div>
			<!-- 标题 -->
			<div class="login-title">{{$system_.state.applic_name}}</div>
			<!-- 登录 -->
			<div class="login-body">
				<div class="account-div"  v-if="whichLogin === 'account'">
					<el-form class="login-form" ref="formRef" :model="forms" :rules="rules">
						<el-form-item prop="username">
							<el-input v-model="forms.username" maxlength="25" prefix-icon="Avatar" placeholder="请输入用户手机号码">
							</el-input>
						</el-form-item>
						<el-form-item prop="password">
							<el-input v-model="forms.password" maxlength="50" prefix-icon="Lock" placeholder="请输入密码"
								:show-password="true">
							</el-input>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" class="login-submit" @click="submitForm()">登录</el-button>
						</el-form-item>
					</el-form>
				</div>
				<div class="code-div" id="login_container" v-else-if="whichLogin === 'code'">
				</div>
			</div>
		</div>
		<!-- 备案 -->
		<div class="login-alert">
			Copyright {{ $system_.state.company_name }} {{ $system_.state.company_tel }}<br>
			{{ $system_.state.applic_name }} {{ $system_.state.system_version }}
			<a target="_blank" href="https://beian.miit.gov.cn">{{ $system_.state.system_record_no }}</a><br>
			{{ $system_.state.technical_support }}
		</div>
	</div>
</template>

<script setup>
	import {
		getCurrentInstance,
		ref,
		reactive,
		onMounted
	} from 'vue'

	// 访问 globalProperties
	const ctx = getCurrentInstance().appContext.config.globalProperties

	// 表单信息
	const forms = reactive({
		username: "",
		password: ""
	})

	// 设置表单ref
	const formRef = ref()

	// 设置表单验证规则
	const rules = reactive({
		username: [{
			required: true,
			message: '请输入用户手机号码',
			trigger: 'blur'
		}],
		password: [{
			required: true,
			message: '请输入登录密码',
			trigger: 'blur'
		}]
	})

	// 腾讯防水墙初始化变量
	let captcha;

	// 提交登录
	const submitForm = () => {
		formRef.value.validate((valid) => {
			if (valid === true) {
				// 构造007防水墙客户端
				if (!captcha) {
					captcha = new TencentCaptcha(ctx.$system_.state.captcha_appid, function(
						tencentRes) {
						if (parseInt(tencentRes.ret) === 0) {
							tencentRes.username = forms.username;
							tencentRes.password = forms.password;

							ctx.$request_nl_.post(ctx.$api_.state.Frame.Login
								.login_account.url, tencentRes).then(respon => {
								if (respon.Code === ctx.$code_.state.success) {
									ctx.$notify({
										title: '温馨提示',
										message: respon.Message,
										type: 'success'
									})

									ctx.$router.push({
										name: 'frame'
									})
								} else {
									ctx.$notify({
										title: '温馨提示',
										message: respon.Message,
										type: 'warning'
									})
								}
							})
						} else {
							ctx.$notify({
								title: '温馨提示',
								message: '防水墙验证失败，请重试！',
								type: 'error'
							});
						}
					});
				}

				// 显示防水墙
				captcha.show();
			} else {
				return false;
			}
		});
	}

	// 登录方式
	const whichLogin = ref('code');

	// 切换登录方式
	const switchLogin = () => {
		if(whichLogin.value === 'account') {
			whichLogin.value = 'code';
			getLoginBefore();
		} else if(whichLogin.value === 'code') {
			whichLogin.value = 'account'
		}
	}

	// 获取二维码
	const getLoginBefore = () => {
		ctx.$request_nl_.post(ctx.$api_.state.Frame.Login.get_login_before.url).then(respon => {
			if (respon.Code === ctx.$code_.state.success) {
				let data = {
					id: "login_container",
					appid: respon.Data.appid,
					agentid: respon.Data.agentid,
					redirect_uri: encodeURIComponent(`${window.location.origin}${window.location.pathname}#/Init`),
					state: respon.Data.state,
				};
				window.WwLogin(data);
			} else {
				ctx.$notify({
					title: '温馨提示',
					message: respon.Message,
					type: 'warning'
				})
			}
		}).catch(error => {
			ctx.$notify({
				title: '温馨提示',
				message: error,
				type: 'error'
			});
		})
	}

	const ifAccount = reactive({
		num: 0
	})
	onMounted(() => {
		getLoginBefore();
		// 判断是否放开账户登录
		let a = localStorage.getItem('systemInfo')
		ifAccount.num = JSON.parse(a).login_conf.account
	})
</script>

<style lang="less">
	#login-acc {
		height: 100vh;
		background-size: 100% !important;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		.login-div {
			// background-color: rgba(255, 255, 255, 0.8);
			background-color: #ffffff;
			box-shadow: 0 10px 80px 0 rgba(0,72,32,.1);
    	border-radius: 10px;
			padding: 5px;
			.login-box {
				display: flex;
				align-items:center;
				justify-content: flex-end;
				.box-text {
					width: 92px;
					height: 24px;
					border-radius: 5px 0 5px 5px;
					border: 1px solid #ccc;
					font-size: 12px;
					line-height: 24px;
					text-align: center;
					color: #ccc;
					cursor: pointer;
				}
				.box-img {
					cursor: pointer;
				}
			}
			.login-title {
				color: #333333;
				height: 10vh !important;
				line-height: 10vh;
				font-size: 1.5vw;
				text-align: center;
				font-weight: 600;
				letter-spacing: 4px;
			}

			.login-body {
				width: 400px;
				// height: 280px;
				margin: 0 auto;
				// background: rgba(0, 0, 0, 0.2);
				.account-div {
					.login-form {
						padding-top: 3vh;
						margin: 0 auto;
						width: 360px;

						.el-form-item {
							margin-bottom: 1.8vh;

							.el-input__inner {
								height: 4.6vh;
								line-height: 4.6vh;
							}
						}

						.login-submit {
							width: 100%;
							height: 4.6vh;
						}
					}
				}
				.code-div {
					width: 400px;
					height: 400px;
					display: flex;
					align-items: center;
					justify-content: center;
					flex-direction: column;
					.code-title {
						display: flex;
						align-items: center;
						justify-content: center;
						margin-bottom: 10px;
						.code-text {
							width: 160px;
							height: 24px;
							line-height: 24px;
							margin: 0 auto;
							color: #0082EF;
							font-size: 20px;
							text-align: left;
						}
					}
				}
			}
		}
		.login-alert {
			line-height: 25px;
			font-size: 14px;
			color: #737C84;
			text-align: center;
			margin-top: 30px;
			a {
				text-decoration: none;
				color: #737C84;
			}
		}
	}
</style>
